import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "./store";
import Layouts from "./layout/Layouts";
import PrivateRoute from "./routes/PrivateRoute";
import server from "./config/server";
import Spinner from "./componentes/Spinner";
import Home from "./screen/Home";

const CalificacionesClientesTodos = lazy(() =>
  import("./screen/CalificacionesClientesTodos")
);
const Notfound = lazy(() => import("./screen/Notfound"));
const LoginHome = lazy(() => import("./screen/LoginHome"));
const RegisterHome = lazy(() => import("./screen/RegisterHome"));
const RecuperarHome = lazy(() => import("./screen/RecuperarHome"));
const MicuentaHome = lazy(() => import("./userScreens/MicuentaHome"));
const EditarCuentaHome = lazy(() => import("./userScreens/EditarCuentaHome"));
const EditarContraseñaHome = lazy(() =>
  import("./userScreens/EditarContraseñaHome")
);
const GrupoId = lazy(() => import("./screen/GrupoId"));
const ResultadosHome = lazy(() => import("./userScreens/ResultadosHome"));
const ResultadosGenerales = lazy(() => import("./userScreens/ResultadosGenerales"));
const ResultadosGeneralesId = lazy(() => import("./userScreens/ResultadosGeneralesId"));
const ResultadosId = lazy(() => import("./userScreens/ResultadosId"));
const ResultadosHome1 = lazy(() => import("./userScreens/ResultadosHome1"));
const ParticipantesScreen = lazy(() => import("./screen/ParticipantesScreen"));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: false };
  }

  componentDidMount() {
    const API = server.baseURL + "desafio/paginas/configuraciones";
    fetch(API, {
      method: "GET",
    })
      .then((response) => response.json())

      .then((data) => {
        //console.log("config");

        localStorage.setItem("configServer", JSON.stringify(data.data));

        this.setState({
          status: true,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let loading;
    if (this.state.status === false) {
      loading = (
        <span>
          <Spinner />
        </span>
      );
    } else {
      loading = (
        <Switch>
          <PrivateRoute exact path="/" component={Home}></PrivateRoute>
          <PrivateRoute
            exact
            path="/grupo/:id"
            component={GrupoId}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/grupo/participantes/:id"
            component={ParticipantesScreen}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/participantes/:id"
            component={ParticipantesScreen}
          ></PrivateRoute>
          <Route path="/signin" component={LoginHome}></Route>
          <Route path="/register" component={RegisterHome}></Route>
          <Route path="/codigo" component={RecuperarHome}></Route>
      
          <PrivateRoute
            path="/calificaciones-encuentros"
            component={CalificacionesClientesTodos}
          ></PrivateRoute>
          <PrivateRoute
            path="/mi-cuenta"
            component={MicuentaHome}
          ></PrivateRoute>
          <PrivateRoute
            path="/resultados/:id"
            component={ResultadosHome}
          ></PrivateRoute>
          <PrivateRoute
            path="/resultados-generales/:id"
            component={ResultadosGenerales}
          ></PrivateRoute>
          <PrivateRoute
            path="/desafio-generales/:grupo/:id"
            component={ResultadosGeneralesId}
          ></PrivateRoute>
          
          <PrivateRoute
            path="/resultados"
            component={ResultadosHome1}
          ></PrivateRoute>
          <PrivateRoute
            path="/resultados-desafios/:grupo/:id"
            component={ResultadosId}
          ></PrivateRoute>
          <PrivateRoute
            path="/editar-cuenta"
            component={EditarCuentaHome}
          ></PrivateRoute>
          <PrivateRoute
            path="/editar-contraseña"
            component={EditarContraseñaHome}
          ></PrivateRoute>
          

          <Route exact path="*" component={Notfound}></Route>
        </Switch>
      );
    }
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Layouts>
            <Suspense
              fallback={
                <span>
                  <Spinner />
                </span>
              }
            >
              {loading}
            </Suspense>
          </Layouts>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
