import React, { useEffect, useState } from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import imgGrupo from "../assets/imagenes/prode/generales/galeria-de-fotos.png";
import General2 from "../componentes/modals/General2";
import { fetchGrupos } from "../action/especificos/GruposActions";
import server from "../config/server";
import mas from "../assets/imagenes/prode/generales/tres-puntos.png"
import ConsultaServer from "../action/generales/ConsultaServer";

function Home() {
  const [grupos, setGrupos] = useState([]);
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  // const API = server.baseURL + `desafios/grupos/list`;

  // useEffect(() => {
  //   fetch(API, {
  //     method: "GET",
  //     headers: {
  //       Token: `Bearer ${userInfo.token}`
  //     },

  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if(data.data){
  //          setGrupos(data.data);
  //          localStorage.setItem("grupos", JSON.stringify(data.data)); 
  //       }
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  const getData = async () => {
    const grupos = await ConsultaServer(
      `desafios/grupos/list`,
      "GET",
      { Token: `Bearer ${userInfo.token}` }
    );
    localStorage.setItem("grupos", JSON.stringify(grupos.data));
    if (grupos.data) {
      setGrupos(grupos.data);
      
    }
  };
  useEffect(() => {
    getData();
  }, []);

console.log(grupos)
  return (
    <React.Fragment>
                    <div>
        {!grupos ? (
        <div className="imagen-home-promociones1-oculto"></div>
      ) : (
<div className="cont-cont-p2">
          {grupos.map((grupo) => {
            return (
              <div className="cont-boton-princ">
              <div>
                <button
                  className="boton-principal"
                  style={{ backgroundColor: grupo.color_grupo }}
                >
                  <div className="card-logo">
                    <img src={`${config_server.linkImageGrupos}${grupo.imagen_grupo}`} alt="grupo" className="img-logo-g" />
                    
                    <Link to={`/grupo/${grupo.id_grupo}`} className="link-ca">

                      <div className="card-logo-l">
                        <p class="h-card">{grupo.nombre_grupo}</p>{" "}
                        <p className="des"></p>
                      </div>
                    </Link>
                    <Link to={`participantes/${grupo.id_grupo}`} className="link-ca">
                    <img src={mas} alt="grupo" className="img-logo-g2"/>
                    </Link>
                  </div>
                </button>
              </div>
            </div>
            );
          })}
        </div>
      )}
      </div>

    </React.Fragment>
  );
}

export default Home;
