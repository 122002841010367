import React from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import "../App.css";

function BotonUser(props) {
  // const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  return (
    <React.Fragment>
      {userInfo ? (
  <React.Fragment/>
      ) : (

        <Link to="/signin" className="lista-sidebar-signin" style={{display:"none"}}>

            Iniciar sesión
          
        </Link>
      )}
    </React.Fragment>
  );
}

export default BotonUser;
