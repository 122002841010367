import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Tada from 'react-reveal/Tada';
import IconLogo from "../userBotones/IconLogo";
import BotonUser from "../userBotones/BotonUser";
import BotonUser2 from "../userBotones/BotonUser2";
import BotonUserWeb from "../userBotones/BotonUserWeb";
import logohome from "../assets/imagenes/prode/especificas/logo-home-4.png"


class Sidebar1 extends React.Component {


  render () {
    // const closeMenu = () => {
    //   document.querySelector(".sidebar").classList.remove("open");
    // };

    return (
      <React.Fragment>
        
                <Tada >
                <IconLogo/>
        </Tada>
     
      <div className="lista-navbar">

        
        <div className="lista-sidebar-n2222" >
        <BotonUserWeb/>
        </div>
        <div className="lista-sidebar-n">
        <BotonUser2/>
        </div>

        
      </div>
      </React.Fragment>
   );
  }
}
  
export default Sidebar1;
