
const server = {
//baseURL:'https://local.quattropy.com/emprendimiento/s1/public/',
    //baseURL:'https://emprendimientosvirtuales.com.py/s1_cerro/public/',
    //baseURL:'https://emprendimientosvirtuales.com.py/s1_guairena/public/',
baseURL:'https://sospy.org/s1_prode/public/',
    
    urlProducto:''
}

export default server;