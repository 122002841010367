import swal from 'sweetalert';
import clienteAxios from '../../config/axios';




const ConsultaServer = async (url,metodo,headers = {},obj = {}) => {
  
    try {
        let data 
        switch (metodo) {
          case "GET":
            data = await clienteAxios.get(
              url,
              {
                headers: headers
              }
              );
            break;
          case "POST":
            data  = await clienteAxios.post(
              url,obj,
              {
                headers: headers
              }
            );
            break;
        }
     const response = data.data
//console.log("data",response)
      if (response.status === "success") {
     
      } else{
        swal({
          title: "¡Ups!",
          text: response.message,
          icon: "",
          dangerMode: true,
        }).then((willDelete) => {
            switch (response.code) {
                case 0:
                    
                    break;
            
                default:
                    window.location.reload();
                    break;
            }
            
        });
        
      }
      return response;
    } catch (error) {
       swal({
        title: "¡Ups !",
        text: error.message,
        icon: "error",
        dangerMode: true,
      })
      .then(willDelete => {
        if (willDelete) {
         window.location.replace('/');
         
        }
      });
      return [];
    }
   
  };
  export default ConsultaServer