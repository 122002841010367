import {
  FETCH_PRODUCTS_PREM, PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS
} from "../../constants/ProductType";
import {OBTENER_PRODUCTO_EDITAR,PRODUCTO_EDITAR_EXITO,PRODUCTO_EDITAR_ERROR} from "../../constants/TablasTtype";
import swal from 'sweetalert';
import ConsultaServer from "../generales/ConsultaServer"
import clienteAxios from '../../config/axios';


export const fetchGrupos = () => async (dispatch) => {
    // dispatch({
    //   type: PRODUCT_LIST_REQUEST,
    // });
  
    try {
      const data = await clienteAxios.get('clientes/desafios/grupos/list');
      dispatch({ type: FETCH_PRODUCTS_PREM, payload: data.data });
      localStorage.setItem("grupos", JSON.stringify(data.data));  
    } catch (error) {
    //   dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message }); 
        swal({
          title: "¡Ups !",
          text: error.message,
          icon: "error",
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
           window.location.replace('/');
           
          }
        });
    }
  };
  

  export const detailsProduct = (productId) => async (dispatch,getState) => {

    try {
      const {
        userSignin: { userInfo },
      } = getState();
      dispatch({ type: PRODUCT_LIST_REQUEST, payload: productId });
      const data = await clienteAxios.get(`desafios/grupos/listIntegrantes/${productId}`,{ headers: { Token: `Bearer ${userInfo.token}` }});
      //console.log("details", data);
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
      swal({
        title: "¡Ups !",
        text: error.message, 
        icon: "error",
        dangerMode: true,
      })
      .then(willDelete => {
        if (willDelete) {
         window.location.replace('/');
         
        }
      });
    }
   
  };
    //funcion para obtener el producto a editar
    export function getProductAction(id) {
      return async (dispatch,getState) => {
        dispatch(getEditProductsAction());
    
        //consultar a la api
        try {
          const {
            userSignin: { userInfo },
          } = getState();
          const data = await ConsultaServer(`desafios/grupos/listIntegrantes/${id}`, "GET",{ Token: `Bearer ${userInfo.token}` });
          dispatch(getProductEditExito(data.data));
        } catch (error) {
          dispatch(getProductEditError());
        }
      };
    }
    
    export const getEditProductsAction = (id) => ({
      type: OBTENER_PRODUCTO_EDITAR,
    });
    
    export const getProductEditExito = (product) => ({
      type: PRODUCTO_EDITAR_EXITO,
      payload: product,
    });
    
    export const getProductEditError = () => ({
      type: PRODUCTO_EDITAR_ERROR,
    });
  

        //funcion para obtener el producto a editar
    export function getEncuentros(id) {
          return async (dispatch,getState) => {
            dispatch(getEditEncuentros());
        
            //consultar a la api
            try {
              const {
                userSignin: { userInfo },
              } = getState();
              const data = await ConsultaServer(`desafios/grupos/listEncuentros/${id}`, "GET",{ Token: `Bearer ${userInfo.token}` });
              dispatch(getEncuentrosEditExito(data.data));
            } catch (error) {
              dispatch(getEncuentrosEditError());
            }
          };
        }
        
        export const getEditEncuentros = (id) => ({
          type: OBTENER_PRODUCTO_EDITAR,
        });
        
        export const getEncuentrosEditExito = (product) => ({
          type: PRODUCTO_EDITAR_EXITO,
          payload: product,
        });
        
        export const getEncuentrosEditError = () => ({
          type: PRODUCTO_EDITAR_ERROR,
        });
      